<template>
  <page-main
    title="Basic education information"
    nextText="Tests"
    buttonTabIndex="15"
    eventName="nav"
    @nav="nav('/EducationEntryDegree/' + key)"
    @save="save()"
  >
    <template v-slot:aside>
      Covering the basics about your education.
      <ul>
        <li>
          If you did not go to university or college but still went to trade
          school or high school, enter the name of that school in the
          "Institution Name" section.
        </li>
        <li>
          If you have multiple majors, create a new, separate educational entry
          to reflect each major.
        </li>
        <li>Be sure to enter your degree and your area of study/major.</li>
        <li>
          If you don't want to include your GPA, feel free to leave those fields
          blank.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >University or College Name:</span
                >
                University of the Spheres
              </li>
              <li>
                <span class="font-weight-bold">School Name:</span>
                Resumation School of Business
              </li>
              <li>
                <span class="font-weight-bold">City:</span>
                Chicago
              </li>
              <li>
                <span class="font-weight-bold">State:</span>
                IL
              </li>
              <li>
                <span class="font-weight-bold">Country:</span>
                USA
              </li>
              <li class="mt-4">
                <span class="font-weight-bold">Degree Type:</span>
                Bachelor of Science (B.S.)
              </li>
              <li>
                <span class="font-weight-bold">Area of Study:</span>
                Chemical Engineering
              </li>
              <li>
                <span class="font-weight-bold">Minors:</span>
                Finance; Engineering Science
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="universityName"
            label="Institution Name (University or College Name)"
            tabindex="1"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="schoolName"
            label="School Name (within University - if applicable)"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="universityCity"
            label="City"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="universityState"
            label="State"
            tabindex="4"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="country"
            label="Country"
            tabindex="5"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-single-select
            v-model="degreeName"
            label="Degree Type"
            tabindex="6"
            autofocus
            :items="baseDegrees"
          ></r-single-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="degreeArea"
            label="Area of study"
            tabindex="7"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="minors"
            label="Minor(s)"
            tabindex="8"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-switch
            v-model="graduated"
            label="Have you graduated?"
            tabindex="9"
          ></r-switch>
        </v-col>
        <v-col cols="12" class="r-col">
          <month-date-select
            label="Date Started"
            :value.sync="dateStart"
            tabindex="10"
          ></month-date-select>
        </v-col>
        <transition name="line-82-swap">
          <v-col cols="12" class="r-col" v-if="graduated">
            <month-date-select
              label="Date Completed"
              :value.sync="dateEnd"
              tabindex="11"
            ></month-date-select>
          </v-col>
        </transition>
        <transition name="line-82-swap">
          <v-col cols="12" class="r-col" v-if="!graduated">
            <r-switch
              v-model="hasExpectedGrad"
              label="Have an expected graduation date?"
              tabindex="12"
              class="max-h-82"
            >
            </r-switch>
          </v-col>
        </transition>
        <transition name="line-82">
          <v-col cols="12" class="r-col" v-if="hasExpectedGrad && !graduated">
            <month-date-select
              label="Expected Graduation Date"
              :value.sync="dateExpected"
              tabindex="13"
              :extraYears="6"
            ></month-date-select>
          </v-col>
        </transition>
        <v-col cols="12" class="r-col">
          <r-text-field v-model="gpa" label="GPA" tabindex="14"></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="gpaScale"
            label="GPA Scale (ie 4.0, 5.0)"
            tabindex="14"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

// @ is an alias to /src
export default {
  name: "EducationEntryGeneral",
  data: () => ({
    currentDisplay: "Present",
  }),
  components: {},
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }
  },
  methods: {
    save() {
      this.saveEducation();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapMutations("education", ["createEntry"]),
    ...mapActions("education", ["saveEducation"]),
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist", "baseDegrees"]),
    ...mapFields("education", {
      key: "key",
      eduDisplay: "educationGeneral.display",
      universityName: "educationGeneral.universityName",
      schoolName: "educationGeneral.schoolName",
      universityCity: "educationGeneral.universityCity",
      universityState: "educationGeneral.universityState",
      country: "educationGeneral.country",
      graduated: "educationGeneral.graduated",
      dateStart: "educationGeneral.dateStart",
      dateEnd: "educationGeneral.dateEnd",
      hasExpectedGrad: "educationGeneral.hasExpectedGrad",
      dateExpected: "educationGeneral.dateExpected",
      gpaDisplay: "educationGPA.display",
      gpa: "educationGPA.gpa",
      gpaScale: "educationGPA.gpaScale",
      degreeName: "educationDegree.degreeName",
      degreeArea: "educationDegree.degreeArea",
      degreeDisplay: "educationDegree.display",
      minors: "educationDegreeMinor.minors",
      minorDisplay: "educationDegreeMinor.display",
    }),
    tips() {
      return [
        "School name is the name of a specific institution within the university, for example, a business school. In many cases, this may not apply to you.",
        "Separate multiple minors with a semicolon ( ; ).",
      ];
    },
  },
};
</script>

<style>
.container-main ul > li {
  margin: 16px 0;
}

/* The month-date-select control is 82 px high */
.max-h-82 {
  max-height: 82px;
}
</style>
